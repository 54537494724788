
import React, { Component } from 'react';

class StyledImg extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { caption, src } = this.props; 
        
        return <div style={{ display: 'flex',
                             flexDirection: 'column',
                             justifyContent: 'center',
                             marginTop: '3%',
                             marginBottom: '1%'}}>

			<img src={src ? src : ''} style={{ alignSelf: 'center', border: '10px' }} />
            <p style={{ display: 'flex',
                       fontSize: '18px',
                       marginTop: '-15px',
                       justifyContent: 'center' }}>
                <i>{caption}</i>
            </p>
        </div>
    }
}

export default StyledImg;
