import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import SpiderverseDisplayImage from "../components/images/spiderverse_display_image"
import SpiderverseDisplayImage from "../images/sv_screenshot_high_expose.png"
import PosterizeBleedDisplayImage from "../components/images/perlin_bleed_display_image.gif"
import Dynamic2DCameraDisplayImage from "../images/dynamic_2d_camera_images/display_short.gif"
import MeshSlicerDisplayImage from "../images/mesh_slicer_images/mainmeshslicer.gif";

import SEO from "../components/seo"
import StyledImg from "../components/styled-img";


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      
      <Link to="spiderverse"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
      >
        <h1>Spiderverse Shader</h1>
        <p>A shader inspired by Spiderman: Into the Spiderverse (Unity/HLSL)</p>
        <StyledImg src={SpiderverseDisplayImage} />
      </Link>

      <Link to="diffused-posterization"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
      >
        <h1>Diffused Posterization</h1>
        <p>Adding Noise to Posterization of 0-1 (Unity/HLSL)</p>
    		<StyledImg src={PosterizeBleedDisplayImage} />
      </Link>

      <Link to="dynamic-2d-camera"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
      >
        <h1>Dynamic 2D Camera</h1>
        <p>A simple, extensible camera for 2D games.</p>
    		<StyledImg src={Dynamic2DCameraDisplayImage} />
      </Link>

      <Link to="mesh-slicer"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
      >
        <h1>Mesh Slicer</h1>
        <p>A quick overview of slicing meshes in Unity/C#</p>
    		<StyledImg src={MeshSlicerDisplayImage} />
      </Link>
    
    </div>
  </Layout>
)

export default IndexPage
